import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { MeliorTranslate } from '../MeliorTranslate';
import { Text } from '../UI/Typography';
import { MeliorTable } from '../MeliorTable';
import { useTranslation } from 'react-i18next';
import { useDocuments } from '../../hooks/useDocuments';
import { useState } from 'react';
import { PAGINATION_LIMIT } from '../../constants';
import Container from '../UI/Common/Container';
import { StyledDocumentFiltersContainer } from '../UI/DocumentFilters/DocumentFiltersContainer.styled';
import DocumentFilters from '../UI/DocumentFilters';
import { IDocument } from '../../@types/Document';
import { DocumentStatus } from '../../enums/DocumentStatus';
import DocumentTypeChip from '../UI/DocumentTypeChip/DocumentTypeChip';

interface IDocumentTableSelectorProps {
    selectedDocuments: IDocument[];
    setSelectedDocuments: (val: IDocument[]) => void;
}

const DocumentTableSelector = ({
    selectedDocuments,
    setSelectedDocuments,
}: IDocumentTableSelectorProps) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedType, setSelectedType] = useState<string>('All');

    const params = {
        page: page,
        size: PAGINATION_LIMIT,
        sort_by: '_upload_timestamp',
        sort_order: -1,
        name_filter: searchText,
        type_filter: selectedType === 'All' ? '' : selectedType,
    };

    const { isLoading, isFetching, error, documents } = useDocuments(params);

    const showLoading = isLoading || isFetching;

    function selectDocument(documentItem: IDocument) {
        const temp = [...selectedDocuments];
        if (Boolean(selectedDocuments.find((doc) => doc.id === documentItem.id))) {
            setSelectedDocuments(temp.filter((item: IDocument) => item.id !== documentItem.id));
            return;
        }

        setSelectedDocuments([...selectedDocuments, documentItem]);
    }

    return (
        <>
            <Container>
                <StyledDocumentFiltersContainer isModal={true}>
                    <DocumentFilters
                        searchText={searchText}
                        selectedType={selectedType}
                        setSearchText={setSearchText}
                        setSelectedType={setSelectedType}
                    />
                </StyledDocumentFiltersContainer>
            </Container>
            <Container topOuterSpacing={1}>
                <MeliorTable
                    showLoading={showLoading}
                    error={error}
                    items={documents}
                    page={page}
                    setPage={setPage}
                    tableEl={
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <Text customFontWeight={700}>
                                                <MeliorTranslate valueKey="File Name" />
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text customFontWeight={700}>
                                                <MeliorTranslate valueKey="Document Type" />
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {documents
                                        .filter((doc) => doc.status === DocumentStatus.READY)
                                        .map((document: IDocument) => (
                                            <TableRow
                                                key={document.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell width="3%">
                                                    <Checkbox
                                                        disabled={
                                                            document.status ===
                                                            DocumentStatus.PROCESSING
                                                        }
                                                        checked={Boolean(
                                                            selectedDocuments?.find(
                                                                (doc) => doc.id === document.id
                                                            )
                                                        )}
                                                        onClick={() => selectDocument(document)}
                                                    />
                                                </TableCell>
                                                <TableCell width="32%">
                                                    <Container minWidth={20} wordBreak>
                                                        {document.name}
                                                    </Container>
                                                </TableCell>
                                                <TableCell width="15%">
                                                    <DocumentTypeChip label={document.type} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    loadingMessage="Loading documents"
                    emptyMessage={t('No documents found')}
                    errorMessage="An error has occurred"
                />
            </Container>
        </>
    );
};

export default DocumentTableSelector;

import Flex from 'styled-flex-component';
import { Text } from '../../UI/Typography';
import Container from '../../UI/Common/Container';
import { isEmpty } from 'lodash';
import { Theme } from '../../../theme';
import Empty from '../../UI/Common/Empty';
import {
    ArrowDropDown,
    ExpandLess,
    KeyboardArrowRight,
    PlaylistAddCheck,
} from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Box,
    Button,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { IFeedback } from '../../../@types/Feedback';
import { provideFeedback } from '../../../api/feedback';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../pages/Client/GetInsights/context/getInsightsContext';
import { useSearchParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { IDisplayClause } from '../../../@types/Comparison';
import { toast } from 'react-toastify';
import ClausesListItem from './ClausesListItem';
import { useTranslation } from 'react-i18next';
import { createTemplate, getTemplates } from '../../../api/template.api';
import { useClauseLibrary } from '../../../hooks/useClauseLibrary';
import { ITemplate } from '../../../@types/Template';
import CreateListModal from '../../Templates/CreateListModal';
import SelectSubheader from './SelectSubheader';
import HiddenInsightsBadge from '../../UI/Badge/HiddenInsightsBadge';
import { MeliorTranslate } from '../../MeliorTranslate';
import { DynamicInsightsDataAnswer } from '../../../@types/Document';
import { DYNAMIC_INSIGHT_PREPEND_INDICATOR } from '../../../constants/dynamicInsightIndicator';
import SelectedClauseItem from './SelectedClauseItem';

interface ICLausesListProps {
    getDocument: () => void;
    sidebarSize: number;
    newDocType: string;
}

const ClausesList = ({ getDocument, sidebarSize, newDocType }: ICLausesListProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const clauseSearchParam = searchParams.get('clause');
    const {
        documentData,
        selectedInsight,
        editSelectedClause,
        selectedRegion,
        textMatchIndex,
        isRepublicHeaderWidgetOpen,
        setSelectedRegion,
        setEditSelectedClause,
        setSelectedInsight,
        setTextMatchIndex,
    } = useContext(GetInsightsContext) as GetInsightsContextType;
    // const clauses = documentData.clauses ? Object.keys(documentData.clauses) : [];
    const clauses = getClauses();
    const [hoveredInsight, setHoveredInsight] = useState<string>();
    const [draggedInsight, setDraggedInsight] = useState<string>();
    const [initialClauses, setInitialClauses] = useState<Array<IDisplayClause>>([]);
    const [displayedClauses, setDisplayedClauses] = useState<Array<IDisplayClause>>([]);
    const [hiddenClauses, setHiddenClauses] = useState<Array<IDisplayClause>>([]);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [isResetting, setIsResetting] = useState<boolean>(false);
    const [isDisplayingBack, setIsDisplayingBack] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isFetchingClauses, setIsFetchingClauses] = useState<boolean>(false);
    const { clauseLibrary } = useClauseLibrary();
    const [unlistedClauses, setUnlistedClauses] = useState<Array<string>>([]);
    const [currTemplate, setCurrTemplate] = useState<string>();
    const [templates, setTemplates] = useState<Array<ITemplate>>([]);
    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [docType, setDocType] = useState<string>('');

    const isEditing = (key) => Boolean(editSelectedClause && editSelectedClause.clause !== key);

    function getClauses(): string[] {
        const clauses: string[] = documentData.clauses ? Object.keys(documentData.clauses) : [];

        const allClausesArr = getDynamicInsightsClauses().concat(clauses);

        return allClausesArr.filter(
            (item, index, arr) =>
                index ===
                arr.findIndex(
                    (foundItem) =>
                        foundItem.replace(DYNAMIC_INSIGHT_PREPEND_INDICATOR, '') ===
                        item.replace(DYNAMIC_INSIGHT_PREPEND_INDICATOR, '')
                )
        );
    }

    function getDynamicInsightsClauses(): string[] {
        const dynamicDataInsights: string[] = [];

        if (!documentData.dynamic_insights) return dynamicDataInsights;

        for (const key in documentData.dynamic_insights) {
            documentData.dynamic_insights[key].answers.map((answer: DynamicInsightsDataAnswer) => {
                dynamicDataInsights.push(
                    `${DYNAMIC_INSIGHT_PREPEND_INDICATOR}${answer.question.alias}`
                );
            });
        }

        return dynamicDataInsights;
    }

    /**use effect for checking if clause query param exists, select that clause as insight */
    useEffect(() => {
        setDocType(documentData.type);
        setIsFetchingClauses(true);
        getTemplates().then((res) => {
            setTemplates(res);
            getTemplateData(res);
        });
    }, []);

    useEffect(() => {
        setDocType(!isEmpty(newDocType) ? newDocType : documentData.type);
    }, [newDocType]);

    useEffect(() => {
        if (!clauseLibrary) return;

        getUnlistedClauses();
    }, [clauseLibrary]);

    useEffect(() => {
        if (!isEmpty(currTemplate) && currTemplate != 'None') getUnlistedClauses();
    }, [currTemplate]);

    const getUnlistedClauses = () => {
        if (!clauseLibrary) return setUnlistedClauses([]);

        const unlisted = clauseLibrary
            .filter((clause) => !clauses.includes(clause.name))
            .map((clause) => clause.name);
        setUnlistedClauses(unlisted);
    };

    const getTemplateData = (templates) => {
        const defaultTemplate = isEmpty(templates)
            ? []
            : templates.find((saved) => saved.linked_doc_types.includes(documentData.type));

        if (!isEmpty(defaultTemplate)) {
            // Get all visible clauses
            applyTemplate(defaultTemplate.visible);
            setCurrTemplate(defaultTemplate.template_name);
        } else {
            const clausesWithId = attachClauseId(clauses, 0);
            // Set all clauses as displayed clauses
            setDisplayedClauses(clausesWithId);
            setInitialClauses(clausesWithId.concat([]));
        }

        setIsFetchingClauses(false);
        if (!clauseSearchParam) return;
        if (!clauses.includes(clauseSearchParam)) return;

        setSelectedInsight(clauseSearchParam);
    };

    const applyTemplate = (visibles) => {
        // Merge clauses in saved template with document clauses
        const sessionClausesInDocument = visibles.filter((insight) => clauses.includes(insight));
        // Attach ID to each clause
        const clausesWithId = attachClauseId(sessionClausesInDocument, 0);
        // Set displayed clauses
        setDisplayedClauses(clausesWithId);
        setInitialClauses(clausesWithId.concat([]));

        // Set the rest of the clauses as hidden
        const documentClausesNotInSession = clauses.filter(
            (insight) => !visibles.includes(insight)
        );
        // Attach ID to each clause
        const hiddenClausesWithId = attachClauseId(
            documentClausesNotInSession,
            sessionClausesInDocument.length
        );
        // Set hidden clauses
        setHiddenClauses(hiddenClausesWithId);
        setIsFetchingClauses(false);
    };

    useEffect(() => {
        if (isExpanded || isDisplayingBack) {
            scrollToDisplayBottom();
        }
        if (isDisplayingBack) setIsDisplayingBack(false);
    }, [isExpanded, displayedClauses, isDisplayingBack]);

    useEffect(() => {
        setHasChanges(JSON.stringify(displayedClauses) != JSON.stringify(initialClauses));
    }, [draggedInsight, displayedClauses.length]);

    const attachClauseId = (clauses, startIndex) => {
        const clausesWithId: Array<IDisplayClause> = [];
        clauses.forEach((item, i) => {
            clausesWithId.push({
                id: (startIndex + i + 1).toString(),
                key: item,
            });
            return;
        });
        return clausesWithId;
    };

    async function reset() {
        setIsResetting(true);

        const feedback: IFeedback[] = [
            {
                correct: false,
                gt: documentData.clauses[editSelectedClause!.clause][textMatchIndex]._predicted
                    ?.answer as string,
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause?.clause}`,
                key: 'answer',
            },
            {
                correct: false,
                gt: documentData.clauses[editSelectedClause!.clause][textMatchIndex]._predicted
                    ?.bboxes as number[][],
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause!.clause}`,
                key: 'bboxes',
            },
        ];
        try {
            await provideFeedback(documentData.id, feedback);
            setEditSelectedClause(undefined);
            setSelectedRegion(undefined);
            setIsResetting(false);
            getDocument();
        } catch (e) {
            console.error(e);
            setIsResetting(false);
        }
    }

    const getBboxesValue = (): number[][] => {
        const arr: number[][] = [];
        if (!selectedRegion?.bboxes.length) {
            return [];
        }

        selectedRegion.bboxes.map((bbox) => {
            arr.push([bbox.left, bbox.top, bbox.width, bbox.height]);
        });

        return arr as number[][];
    };

    async function confirm() {
        if (!selectedRegion || !editSelectedClause) return;

        setIsConfirming(true);
        const feedback: IFeedback[] = [
            {
                correct: false,
                gt: selectedRegion.text,
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause.clause}`,
                key: 'answer',
            },
            {
                correct: false,
                gt: getBboxesValue(),
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause.clause}`,
                key: 'bboxes',
            },
            {
                correct: false,
                gt: selectedRegion.page,
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause.clause}`,
                key: 'page',
            },
        ];

        try {
            await provideFeedback(documentData.id, feedback);
            setEditSelectedClause(undefined);
            setIsConfirming(false);
            setSelectedRegion(undefined);
            getDocument();
        } catch (e) {
            console.error(e);
            setIsConfirming(false);
        }
    }

    const dragEnd = (result) => {
        setDraggedInsight('');
        const { destination, source, draggableId } = result;
        // Released outside of droppable area
        if (!destination) return;
        // Order not changed
        if (source.index == destination.index && source.droppableId == destination.droppableId)
            return;

        if (source.droppableId != destination.droppableId) {
            //remove from source
            const sourceList = source.droppableId == 'displayed' ? displayedClauses : hiddenClauses;
            const clause = sourceList.find((clause) => clause.id == draggableId);
            sourceList.splice(source.index, 1);
            //insert to destination
            const destinationList =
                destination.droppableId == 'displayed' ? displayedClauses : hiddenClauses;
            destinationList.splice(destination.index, 0, clause as IDisplayClause);
            // update lists
            const displayed = source.droppableId == 'displayed' ? sourceList : destinationList;
            const hidden = source.droppableId == 'hidden' ? sourceList : destinationList;
            setDisplayedClauses(displayed);
            setHiddenClauses(hidden);

            if (!hidden.length) setIsExpanded(false);

            return;
        }
        const listToUpdate = source.droppableId == 'displayed' ? displayedClauses : hiddenClauses;
        const clause = listToUpdate.find((clause) => clause.id == draggableId);
        listToUpdate.splice(source.index, 1);
        listToUpdate.splice(destination.index, 0, clause as IDisplayClause);
        source.droppableId == 'displayed'
            ? setDisplayedClauses(listToUpdate)
            : setHiddenClauses(listToUpdate);
    };

    const dragStart = (result) => {
        setDraggedInsight(result.draggableId);
    };

    const removeInsight = (e, clause, index) => {
        e.stopPropagation();

        if (hiddenClauses.includes(clause)) return;

        displayedClauses.splice(index, 1);
        setDisplayedClauses(displayedClauses);

        hiddenClauses.unshift(clause);
        setHiddenClauses(hiddenClauses);
        setHoveredInsight('');
    };

    const displayInsight = (clause, index) => {
        setIsDisplayingBack(true);
        if (displayedClauses.includes(clause)) return;

        hiddenClauses.splice(index, 1);
        setHiddenClauses(hiddenClauses);
        if (!hiddenClauses.length) setIsExpanded(false);

        displayedClauses.push(clause);
        setDisplayedClauses(displayedClauses);
        setHoveredInsight('');
    };

    const scrollToDisplayBottom = () => {
        const element: any = document.getElementById('displayed-clauses-bottom');
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    const handleSave = async () => {
        try {
            let templateToSave: ITemplate =
                templates.find((template) => template.template_name == currTemplate) ??
                ({} as ITemplate);
            templateToSave = {
                ...templateToSave,
                linked_doc_types: templateToSave.linked_doc_types
                    .filter((type) => type != docType)
                    .concat(docType),
                visible: displayedClauses.map((clause) => clause.key),
            };

            await createTemplate(templateToSave).then((res) => {
                if (res) {
                    setHasChanges(false);
                    setInitialClauses(displayedClauses.concat([]));
                    toast.success(t('Template saved successfully.'));
                    setTemplates(
                        templates
                            .filter((saved) => saved.template_name != templateToSave.template_name)
                            .concat(templateToSave)
                    );
                }
            });
        } catch (e) {
            toast.error(t('Error saving template.'));
        }
    };

    const isClauseParsed = (key: string) => {
        if (!documentData.clauses[key]) return true; //Dynamic Insights aren't included in this check; true by default

        return (
            (key.includes('Date') && !isEmpty(documentData.clauses[key][0]?.value)) ||
            !key.includes('Date')
        );
    };

    const editSelection = (e, clause) => {
        e.stopPropagation();
        setEditSelectedClause({
            clause: clause.key,
            answer: documentData.clauses[clause.key][textMatchIndex].answer,
        });
        setSelectedRegion(undefined);
    };

    const onSaveTemplateSuccess = (template) => {
        setTemplates(templates.concat(template));
        setOpenCreate(false);
        setHasChanges(false);
        setCurrTemplate(template.template_name);
    };

    return (
        <div>
            <Box
                sx={{
                    borderBottom: 1,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingLeft: 2,
                    paddingRight: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Flex alignCenter>
                    <Text customFontSize={0.9} style={{ marginRight: '20px' }}>
                        <MeliorTranslate valueKey="Insight List" />
                    </Text>
                    {!isFetchingClauses && (
                        <Badge badgeContent={displayedClauses.length} color="primary" />
                    )}
                </Flex>
                <Flex style={{ height: '40px' }}>
                    {hasChanges && displayedClauses.length > 0 && (
                        <Button
                            style={{ flex: 'none' }}
                            variant="text"
                            onClick={() =>
                                !isEmpty(currTemplate) && currTemplate != 'None'
                                    ? handleSave()
                                    : setOpenCreate(true)
                            }
                            size="small"
                        >
                            <MeliorTranslate
                                valueKey={
                                    !isEmpty(currTemplate) && currTemplate != 'None'
                                        ? 'Save'
                                        : 'Create List'
                                }
                            />
                        </Button>
                    )}
                    {!isEmpty(templates) && (
                        <FormControl fullWidth>
                            <IconButton
                                onClick={() => setOpenSelect(!openSelect)}
                                disableRipple={true}
                                sx={{ '&:hover': { color: Theme.highlight } }}
                            >
                                <Tooltip
                                    title={
                                        !isEmpty(currTemplate) && currTemplate != 'None'
                                            ? `${currTemplate} ${t('template has been applied')}`
                                            : t('Apply a template')
                                    }
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            border: '1px solid',
                                            borderColor: Theme.primary,
                                            borderRadius: '30px',
                                            padding: 3,
                                            color: Theme.primary,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                        }}
                                    >
                                        <PlaylistAddCheck />
                                        {!isEmpty(currTemplate) && currTemplate != 'None' && (
                                            <Flex alignCenter>
                                                <Text
                                                    style={{
                                                        marginLeft: '10px',
                                                        width: '80px',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflowX: 'hidden',
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    {currTemplate}
                                                </Text>
                                                <ArrowDropDown />
                                            </Flex>
                                        )}
                                    </div>
                                </Tooltip>
                                <Select
                                    fullWidth
                                    onChange={(e) => {
                                        setCurrTemplate(e.target.value);

                                        const template =
                                            e.target.value == 'None'
                                                ? getDynamicInsightsClauses().concat(
                                                      clauseLibrary.map((clause) => clause.name)
                                                  )
                                                : templates.find(
                                                      (temp) => temp.template_name == e.target.value
                                                  )?.visible;

                                        applyTemplate(template);
                                    }}
                                    value={currTemplate}
                                    open={openSelect}
                                    style={{ visibility: 'hidden', width: 0, height: 0 }}
                                >
                                    {/* None */}
                                    {!isEmpty(currTemplate) && currTemplate != 'None' && (
                                        <MenuItem value={'None'}>{'None'}</MenuItem>
                                    )}

                                    {/* Group 1 - Linked by Document Type */}
                                    {templates.find((template) =>
                                        template.linked_doc_types.includes(docType)
                                    ) && (
                                        <SelectSubheader>
                                            {docType} <MeliorTranslate valueKey="Templates" />{' '}
                                        </SelectSubheader>
                                    )}
                                    {templates.find((template) =>
                                        template.linked_doc_types.includes(docType)
                                    ) &&
                                        templates
                                            .filter((template) =>
                                                template.linked_doc_types.includes(docType)
                                            )
                                            .map((template, index) => (
                                                <MenuItem
                                                    value={template.template_name}
                                                    key={index}
                                                >
                                                    <Text>{template.template_name}</Text>
                                                </MenuItem>
                                            ))}

                                    {/* Group 2 - Others */}
                                    {templates.find(
                                        (template) => !template.linked_doc_types.includes(docType)
                                    ) && (
                                        <SelectSubheader>
                                            {t(
                                                templates.find((template) =>
                                                    template.linked_doc_types.includes(docType)
                                                )
                                                    ? 'Other Templates'
                                                    : 'Templates'
                                            )}
                                        </SelectSubheader>
                                    )}
                                    {templates
                                        .filter(
                                            (template) =>
                                                !template.linked_doc_types.includes(docType)
                                        )
                                        .map((template, index) => (
                                            <MenuItem value={template.template_name} key={index}>
                                                <Text>{template.template_name}</Text>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </IconButton>
                        </FormControl>
                    )}
                </Flex>
            </Box>

            <DragDropContext onDragEnd={dragEnd} onDragStart={dragStart}>
                <Container id="clauses-list">
                    {!Boolean(clauses.length) && (
                        <Container outerSpacing={2}>
                            <Empty message="There are no clauses for this document" />
                        </Container>
                    )}
                    {Boolean(isFetchingClauses) && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 'full',
                                marginTop: 50,
                            }}
                        >
                            <Empty message={t('Fetching insights...')} />
                        </div>
                    )}
                    <Droppable droppableId="displayed">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{
                                    height: isExpanded
                                        ? `calc(50vh - ${isRepublicHeaderWidgetOpen ? '276px' : '208px'})`
                                        : hiddenClauses.length
                                          ? `calc(100vh - ${isRepublicHeaderWidgetOpen ? '321px' : '253px'})`
                                          : `calc(100vh - ${isRepublicHeaderWidgetOpen ? '245px' : '177px'})`,
                                    overflowY: 'auto',
                                }}
                                id="displayed-clauses"
                            >
                                {Boolean(clauses.length) &&
                                    displayedClauses.map((clause, index) => (
                                        <Container key={clause.id}>
                                            {selectedInsight !== clause.key && (
                                                <Draggable draggableId={clause.id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <ClausesListItem
                                                                index={index}
                                                                clause={clause}
                                                                hoveredInsight={
                                                                    hoveredInsight as string
                                                                }
                                                                draggedInsight={
                                                                    draggedInsight as string
                                                                }
                                                                setHoveredInsight={
                                                                    setHoveredInsight
                                                                }
                                                                isEditing={isEditing}
                                                                removeInsight={removeInsight}
                                                                displayInsight={displayInsight}
                                                                setSelectedInsight={
                                                                    setSelectedInsight
                                                                }
                                                                setTextMatchIndex={
                                                                    setTextMatchIndex
                                                                }
                                                                isHideable={true}
                                                                isParsed={isClauseParsed(
                                                                    clause.key
                                                                )}
                                                                editSelection={editSelection}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )}
                                            {selectedInsight === clause.key && (
                                                <SelectedClauseItem
                                                    reset={reset}
                                                    clause={clause}
                                                    isConfirming={isConfirming}
                                                    confirm={confirm}
                                                    isResetting={isResetting}
                                                    removeInsight={(e) =>
                                                        removeInsight(e, clause, index)
                                                    }
                                                    editSelection={(e) => editSelection(e, clause)}
                                                />
                                            )}
                                        </Container>
                                    ))}
                                {provided.placeholder}
                                <div id="displayed-clauses-bottom" />
                                {Boolean(unlistedClauses.length) &&
                                    !isEmpty(currTemplate) &&
                                    currTemplate != 'None' &&
                                    unlistedClauses.map((clause, index) => (
                                        <div key={index} id={clause}>
                                            <Flex
                                                style={{
                                                    height: '80px',
                                                    paddingLeft: '35px',
                                                    borderBottom:
                                                        '1px solid rgba(13, 24, 50, 0.12)',
                                                    backgroundColor: '#eeeeee',
                                                }}
                                                alignCenter
                                            >
                                                <Text
                                                    color={'#666666'}
                                                    dangerouslySetInnerHTML={{ __html: clause }}
                                                />
                                            </Flex>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </Droppable>
                    <Accordion
                        elevation={5}
                        sx={{
                            bottom: 0,
                            minWidth: `${sidebarSize - 0.2}%`,
                            maxHeight: '50vh',
                            display: hiddenClauses.length ? 'block' : 'none',
                            position: 'relative',
                        }}
                        expanded={isExpanded}
                        onChange={(e, expanded) => {
                            setIsExpanded(expanded);
                        }}
                    >
                        <AccordionSummary
                            expandIcon={isExpanded ? <ExpandLess /> : <KeyboardArrowRight />}
                            style={{
                                height: '75px',
                                borderBottom: hiddenClauses.length
                                    ? '1px solid rgba(13, 24, 50, 0.12)'
                                    : '',
                                width: '100%',
                            }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Flex alignCenter>
                                <Text color={Theme.primaryDark} style={{ marginRight: '20px' }}>
                                    Other Insights
                                </Text>
                                <HiddenInsightsBadge content={hiddenClauses.length} />
                            </Flex>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                overflowY: 'auto',
                                height: '40vh',
                                padding: 0,
                            }}
                        >
                            <Droppable droppableId="hidden">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {Boolean(hiddenClauses.length) &&
                                            hiddenClauses.map((clause, index) => (
                                                <Container key={clause.id}>
                                                    <Draggable
                                                        draggableId={clause.id}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <ClausesListItem
                                                                    index={index}
                                                                    clause={clause}
                                                                    hoveredInsight={
                                                                        hoveredInsight as string
                                                                    }
                                                                    draggedInsight={
                                                                        draggedInsight as string
                                                                    }
                                                                    setHoveredInsight={
                                                                        setHoveredInsight
                                                                    }
                                                                    removeInsight={removeInsight}
                                                                    displayInsight={displayInsight}
                                                                    setSelectedInsight={
                                                                        setSelectedInsight
                                                                    }
                                                                    isHideable={false}
                                                                    isEditing={isEditing}
                                                                    isParsed={isClauseParsed(
                                                                        clause.key
                                                                    )}
                                                                    editSelection={editSelection}
                                                                    setTextMatchIndex={
                                                                        setTextMatchIndex
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </Container>
                                            ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </AccordionDetails>
                    </Accordion>
                </Container>
            </DragDropContext>

            <CreateListModal
                isOpen={openCreate}
                setIsOpen={setOpenCreate}
                onSaveTemplateSuccess={(template) => onSaveTemplateSuccess(template)}
                maxStep={1}
                docType={docType}
                visibleClauses={displayedClauses.map((clause) => clause.key)}
                mode="new"
            />
        </div>
    );
};

export default ClausesList;

import { IAuditLog } from '../@types/AuditLog';
import apiClient from './apiClient';

export const getAuditLogsByUser = async (startDate?: string, endDate?: string) => {
    return apiClient
        .get<IAuditLog[]>(`/auditlogs/`, {
            params: {
                start_date: startDate?.length ? startDate : undefined,
                end_date: endDate?.length ? endDate : undefined,
            },
        })
        .then((res) => res.data);
};
